<template>
  <div class="InterActionForm-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail>
        <col2-block title="互动信息">
          <el-form-item label="帖子类型" :rules="[{ required: true}]">
            <span>{{form.topicTypeName}}</span>
          </el-form-item>
          <el-form-item label="帖子简介" :rules="[{ required: true}]">
            <span>{{form.topicContent}}</span>
          </el-form-item>
          <el-form-item label="图片" v-if="dealImg[0]">
             <v-preview :imgs="dealImg"></v-preview>
          </el-form-item>
          <el-form-item label="发布时间" :rules="[{ required: true}]">
            <span>{{intime}}</span>
          </el-form-item>
          <el-form-item label="关闭理由" prop="rejectReason"  :rules="[ { required: true, message: '当前选项不允许为空', trigger: 'blur' } ]">
          <checkbox-plus style="display: block;" type="radio" :options="rejectReasonOps" @onChange="change"></checkbox-plus>
          <v-textarea v-model="form.rejectReason" placeholder="其他删帖理由在此输入" :maxlength="100"></v-textarea>
        </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>
<script>
import { editURL, queryURL } from './api'
import { setStatusOps, topicTypeMap } from './map'
import { vPreview } from 'components/control'
import { Col2Detail, Col2Block, CheckboxPlus } from 'components/bussiness'

export default {
  name: 'InterActionForm',
  components: {
    Col2Detail,
    Col2Block,
    vPreview,
    CheckboxPlus
  },
  data () {
    return {
      width: 182,
      statusOps: setStatusOps(),
      topicType: undefined,
      dealImg: [],
      intime: '',
      rejectReasonOps: [
        {
          checked: false,
          label: '此帖含色情、血腥、辱骂、暴力、政治等内容，已被管理员关闭',
          value: '0'
        },
        {
          checked: false,
          label: '此帖含广告、虚假宣传等内容，已被管理员关闭',
          value: '1'
        },
        {
          checked: false,
          label: '此帖含有恶语、辱骂、讥讽等内容，已被管理员关闭',
          value: '2'
        }
      ],
      form: {
        id: undefined,
        rejectReason: '',
        topicType: undefined,
        topicContent: '',
        topicTypeName: ''
      },
      submitConfig: {
        queryUrl: queryURL,
        submitUrl: '',
        submitContentType: 'application/x-www-form-urlencoded; charset=UTF-8'
      }
    }
  },
  mounted () {
    const { id } = this.$route.query
    if (id !== undefined) {
      this.$setBreadcrumb('编辑')
      this.submitConfig.submitUrl = editURL
      this.$refs.formPanel.getData({ id })
    }
  },
  methods: {
    // 更改关闭理由
    change (index) {
      this.form.rejectReason = this.rejectReasonOps[index].label
    },
    update (data) {
      this.form.id = data.id
      this.form.topicType = data.topicType
      this.form.topicTypeName = data.topicTypeName
      Object.keys(topicTypeMap).forEach(key => {
        let topicType = String(data.topicType)
        if (topicType === key) {
          this.topicType = topicTypeMap[key]
        }
      })
      this.form.topicContent = data.topicContent
      if (data.annexs && data.annexs.length > 0) {
        data.annexs.map(item => {
          this.dealImg.push({
            src: item.name
          })
        })
      }
      this.intime = data.intime
      if (data.rejectReason) {
        this.form.rejectReason = data.rejectReason
      }
    },
    submitBefore (data) {
      data.topicStatus = 3
      return true
    }
  }
}
</script>
