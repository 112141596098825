var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "InterActionForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "互动信息" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "帖子类型", rules: [{ required: true }] },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.form.topicTypeName))])]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "帖子简介", rules: [{ required: true }] },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.form.topicContent))])]
                  ),
                  _vm.dealImg[0]
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "图片" } },
                        [_c("v-preview", { attrs: { imgs: _vm.dealImg } })],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "发布时间", rules: [{ required: true }] },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.intime))])]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "关闭理由",
                        prop: "rejectReason",
                        rules: [
                          {
                            required: true,
                            message: "当前选项不允许为空",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("checkbox-plus", {
                        staticStyle: { display: "block" },
                        attrs: { type: "radio", options: _vm.rejectReasonOps },
                        on: { onChange: _vm.change },
                      }),
                      _c("v-textarea", {
                        attrs: {
                          placeholder: "其他删帖理由在此输入",
                          maxlength: 100,
                        },
                        model: {
                          value: _vm.form.rejectReason,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "rejectReason", $$v)
                          },
                          expression: "form.rejectReason",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }